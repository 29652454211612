import { useNavigate } from "react-router-dom";
import { useLanguageStore, useTabStore } from "../../zustand/stores"

export default function Home () { 
    const { language, setLanguage } = useLanguageStore();
    const { isTabOpen, toggleTabOpen } = useTabStore();
    // const { isDarkMode, setIsDarkMode } = useDarkModeStore();
    const navigate = useNavigate();

    const handleNavigationToHome = () => {
        navigate('/');
    };

    const handleNavigationToBlog = () => {
        navigate('/blog');
    };

    const handleNavigationToBlogEnd = () => {
        navigate('/blog/eatsimple_suspending_operations');
    };

    return (
        <div className="bg-[#f4f1eb]">
            <div
                className={`fixed w-full flex flex-col justify-between bg-[#f4f1eb] z-10 transition-all duration-300 ${
                    isTabOpen ? "h-[40vh]" : "h-[6vh]"
                }`}
            >
                <div className="h-[6vh] flex flex-row justify-between">
                    <div 
                        className="xs:w-[20vw] flex flex-row text-2xl sm:text-4xl px-2 mt-1.5 xxs:mt-3 sm:mt-2 cursor-pointer" 
                        style={{letterSpacing: -0.4}}
                        onClick={() => {
                            handleNavigationToHome();
                            if (isTabOpen) {
                                toggleTabOpen();
                            }
                        }}
                    >
                        <div>
                            <img 
                                className="min-w-[35px] h-[35px] sm:w-[45px] sm:h-[45px] object-cover mr-1"
                                src={`/AA_ASSETS/eatsimplelogotransparent.png`}
                                alt="Background"
                            />
                        </div>
                        <span>eatsimple.se</span>
                    </div>

                    <div className="w-[0vw] sm:w-[60vw] h-[6vh] hidden sm:flex flex-row justify-center items-center gap-2">
                        <div className={`hover:opacity-100 duration-150 cursor-no-drop`}>{language === 'sv-SE' ? 'handla' : 'shop'}</div>
                        <div>|</div>
                        <div className={`hover:opacity-70 duration-150 cursor-no-drop`}>{language === 'sv-SE' ? 'om oss' : 'about us'}</div>
                        <div>|</div>
                        <div className={`hover:opacity-70 duration-150 cursor-pointer`} onClick={handleNavigationToBlog}>{language === 'sv-SE' ? 'blogg' : 'blog'}</div>
                        <div>|</div>
                        <div className={`hover:opacity-100 duration-150 cursor-no-drop`}>{language === 'sv-SE' ? 'logga in' : 'log in'}</div>
                    </div>

                    <div className="h-full w-[0vw] sm:w-[20vw] hidden sm:flex flex-row justify-end items-center text-xl gap-1">
                        <div
                            onClick={() => setLanguage('sv-SE')}
                            className={`cursor-pointer duration-300 ${
                            language === 'sv-SE'
                                ? 'opacity-100'
                                : 'opacity-60 hover:opacity-75'
                            }`}
                        >
                            sv
                        </div>

                        <div className="opacity-50">|</div>
                        
                        <div
                            onClick={() => setLanguage('en-US')}
                            className={`cursor-pointer duration-300 mr-[1vw] ${
                            language === 'en-US'
                                ? 'opacity-100'
                                : 'opacity-60 hover:opacity-75'
                            }`}
                        >
                            en
                        </div>
                    </div>

                    <div className="flex sm:hidden h-full w-[12vw] flex-row justify-center items-center mt-0.5 mr-1">
                        <div className={`bi ${isTabOpen ? 'bi-x' : 'bi-list'} text-2xl`} onClick={toggleTabOpen}></div>
                    </div>
                </div>

                <div
                    className={`${
                        isTabOpen ? "h-[34vh]" : "h-[0vh] hidden"
                    } sm:h-[0vh] flex flex-col justify-center items-center sm:hidden transition-all duration-300`}
                >
                    <div className="flex flex-col items-center gap-3">
                        <div className={`hover:opacity-100 duration-150 cursor-no-drop`}>{language === 'sv-SE' ? 'handla' : 'shop'}</div>
                        <div className={`hover:opacity-70 duration-150 cursor-no-drop`}>{language === 'sv-SE' ? 'om oss' : 'about us'}</div>
                        <div className={`hover:opacity-70 duration-150 cursor-pointer`} onClick={() => {handleNavigationToBlog(); toggleTabOpen();}} >{language === 'sv-SE' ? 'blogg' : 'blog'}</div>
                        <div className={`hover:opacity-100 duration-150 cursor-no-drop`}>{language === 'sv-SE' ? 'logga in' : 'log in'}</div>
                    </div>

                    <div className="sm:h-[7vh] mt-[3.5vh] w-[20vw] flex flex-row justify-center items-center sm:text-xl gap-2">
                        <div
                            onClick={() => {
                                setLanguage('sv-SE');
                                toggleTabOpen();
                            }}
                            className={`cursor-pointer duration-300 ${
                            language === 'sv-SE'
                                ? 'opacity-100'
                                : 'opacity-60 hover:opacity-75'
                            }`}
                        >
                            sv
                        </div>

                        <div className="opacity-50">|</div>
                        
                        <div
                            onClick={() => {
                                setLanguage('en-US');
                                toggleTabOpen();
                            }}
                            className={`cursor-pointer duration-300 ${
                            language === 'en-US'
                                ? 'opacity-100'
                                : 'opacity-60 hover:opacity-75'
                            }`}
                        >
                            en
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full flex flex-col items-center relative">
                <div className="w-full h-[6vh] flex flex-row items-center justify-between mt-1"></div>
                
                <div className="w-[95vw] flex flex-row items-center mt-[0.5vh] relative">
                    <div className="w-full h-[91vh] rounded-xl relative overflow-hidden">
                        <img 
                            className="w-full h-full object-cover"
                            src={`/AA_ASSETS/heroimage1.jpg`}
                            alt="Background"
                        />
                    </div>

                    <div className="absolute w-[79%] h-full flex flex-row justify-center">
                        <div className="flex flex-col h-full w-[94%] justify-center text-[1.6rem] xxs:text-4xl xs:text-5xl md:text-6xl lg:text-7xl xl:text-8xl">
                            <div className="flex flex-row">
                                <span className="text-[#FFAE3B] pr-2 sm:pr-3 lg:pr-5">
                                    {language === 'sv-SE' ? 'närproducerad' : 'locally produced'}
                                </span>
                                <span className="text-[#FFF]">
                                    {language === 'sv-SE' ? 'mat' : 'food'}
                                </span>
                            </div>

                            <div className="flex flex-row">
                                <span className="text-[#FFF] pr-2 sm:pr-3 lg:pr-5">
                                    {language === 'sv-SE' ? 'direkt till' : 'direct to'}
                                </span>
                                <span className="text-[#FFAE3B]">
                                    {language === 'sv-SE' ? 'din dörr' : 'your door'}
                                </span>
                            </div>

                            <div 
                                className="w-full h-[12vh] mt-0.5 hover:opacity-70 duration-300 cursor-pointer"
                            >
                                <div className="flex flex-row items-center text-[#a3a3a3] text-xl xs:text-2xl md:text-3xl lg:text-4xl xl:text-5xl" onClick={handleNavigationToBlogEnd}>
                                    {language === 'sv-SE' ? 'viktiga uppdateringar' : 'important updates'}

                                    <div className="mt-1 -ml-1 text-[35px] xs:text-[65px] bi bi-arrow-right-short"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>

            <div className="w-full h-[20vh] flex flex-row items-center justify-center mt-[20vh]">
                <span className="text-xs xs:text-md mt-20">{language === 'sv-SE' ? 'tidigare registrerad som eatsimple LLC, 2024' : 'formerly registered as eatsimple LLC, 2024'}</span>
            </div>
        </div>
    )
}