import { useNavigate } from "react-router-dom";
import { useLanguageStore, useTabStore } from "../../zustand/stores"

export default function Blog () { 
    const { language, setLanguage } = useLanguageStore();
    const { isTabOpen, toggleTabOpen } = useTabStore();
    // const { isDarkMode, setIsDarkMode } = useDarkModeStore();
    const navigate = useNavigate();

    const handleNavigationToHome = () => {
        navigate('/');
    };

    const handleNavigationToBlog = () => {
        navigate('/blog');
    };

    const handleNavigationToBlogEnd = () => {
        navigate('/blog/eatsimple_suspending_operations');
    };

    return (
        <div className="bg-[#f4f1eb]">
            <div
                className={`fixed w-full flex flex-col justify-between bg-[#f4f1eb] z-10 transition-all duration-300 ${
                    isTabOpen ? "h-[40vh]" : "h-[6vh]"
                }`}
            >
                <div className="h-[6vh] flex flex-row justify-between">
                    <div 
                        className="xs:w-[20vw] flex flex-row text-2xl sm:text-4xl px-2 mt-1.5 xxs:mt-3 sm:mt-2 cursor-pointer" 
                        style={{letterSpacing: -0.4}}
                        onClick={() => {
                            handleNavigationToHome();
                            if (isTabOpen) {
                                toggleTabOpen();
                            }
                        }}
                    >
                        <div>
                            <img 
                                className="min-w-[35px] h-[35px] sm:w-[45px] sm:h-[45px] object-cover mr-1"
                                src={`/AA_ASSETS/eatsimplelogotransparent.png`}
                                alt="Background"
                            />
                        </div>
                        <span>eatsimple.se</span>
                    </div>

                    <div className="w-[0vw] sm:w-[60vw] h-[6vh] hidden sm:flex flex-row justify-center items-center gap-2">
                        <div className={`hover:opacity-100 duration-150 cursor-no-drop`}>{language === 'sv-SE' ? 'handla' : 'shop'}</div>
                        <div>|</div>
                        <div className={`hover:opacity-70 duration-150 cursor-no-drop`}>{language === 'sv-SE' ? 'om oss' : 'about us'}</div>
                        <div>|</div>
                        <div className={`hover:opacity-70 duration-150 cursor-pointer`} onClick={handleNavigationToBlog}>{language === 'sv-SE' ? 'blogg' : 'blog'}</div>
                        <div>|</div>
                        <div className={`hover:opacity-100 duration-150 cursor-no-drop`}>{language === 'sv-SE' ? 'logga in' : 'log in'}</div>
                    </div>

                    <div className="h-full w-[0vw] sm:w-[20vw] hidden sm:flex flex-row justify-end items-center text-xl gap-1">
                        <div
                            onClick={() => setLanguage('sv-SE')}
                            className={`cursor-pointer duration-300 ${
                            language === 'sv-SE'
                                ? 'opacity-100'
                                : 'opacity-60 hover:opacity-75'
                            }`}
                        >
                            sv
                        </div>

                        <div className="opacity-50">|</div>
                        
                        <div
                            onClick={() => setLanguage('en-US')}
                            className={`cursor-pointer duration-300 mr-[1vw] ${
                            language === 'en-US'
                                ? 'opacity-100'
                                : 'opacity-60 hover:opacity-75'
                            }`}
                        >
                            en
                        </div>
                    </div>

                    <div className="flex sm:hidden h-full w-[12vw] flex-row justify-center items-center mt-0.5 mr-1">
                        <div className={`bi ${isTabOpen ? 'bi-x' : 'bi-list'} text-2xl`} onClick={toggleTabOpen}></div>
                    </div>
                </div>

                <div
                    className={`${
                        isTabOpen ? "h-[34vh]" : "h-[0vh] hidden"
                    } sm:h-[0vh] flex flex-col justify-center items-center sm:hidden transition-all duration-300`}
                >
                    <div className="flex flex-col items-center gap-3">
                        <div className={`hover:opacity-100 duration-150 cursor-no-drop`}>{language === 'sv-SE' ? 'handla' : 'shop'}</div>
                        <div className={`hover:opacity-70 duration-150 cursor-no-drop`}>{language === 'sv-SE' ? 'om oss' : 'about us'}</div>
                        <div className={`hover:opacity-70 duration-150 cursor-pointer`} onClick={() => {handleNavigationToBlog(); toggleTabOpen();}} >{language === 'sv-SE' ? 'blogg' : 'blog'}</div>
                        <div className={`hover:opacity-100 duration-150 cursor-no-drop`}>{language === 'sv-SE' ? 'logga in' : 'log in'}</div>
                    </div>

                    <div className="sm:h-[7vh] mt-[3.5vh] w-[20vw] flex flex-row justify-center items-center sm:text-xl gap-2">
                        <div
                            onClick={() => {
                                setLanguage('sv-SE');
                                toggleTabOpen();
                            }}
                            className={`cursor-pointer duration-300 ${
                            language === 'sv-SE'
                                ? 'opacity-100'
                                : 'opacity-60 hover:opacity-75'
                            }`}
                        >
                            sv
                        </div>

                        <div className="opacity-50">|</div>
                        
                        <div
                            onClick={() => {
                                setLanguage('en-US');
                                toggleTabOpen();
                            }}
                            className={`cursor-pointer duration-300 ${
                            language === 'en-US'
                                ? 'opacity-100'
                                : 'opacity-60 hover:opacity-75'
                            }`}
                        >
                            en
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full flex flex-col items-center relative px-10">
                <div className="w-full h-[6vh] flex flex-row items-center justify-between mt-1"></div>
                
                <div className="w-full h-[10vh] sm:h-[10vh] flex flex-col justify-end relative">
                    <div className="w-full flex flex-row justify-start">
                        <span className="text-2xl xs:text-3xl md:text-4xl lg:text-5xl xl:text-6xl">{language === 'sv-SE' ? 'Vår Blogg' : 'Our Blog'}</span>
                    </div>
                </div>

                <div className="w-full flex flex-col items-center mt-5 gap-5 md:px-10 relative">
                    <div onClick={handleNavigationToBlogEnd} className="flex flex-row items-center justify-start w-full h-[18vh] p-1 relative cursor-pointer">
                        <div className="absolute w-full h-full bg-[#1f1f1f] opacity-0 hover:opacity-5 duration-200 rounded-lg"></div>
                        <div className="h-[15vh] w-[15vh] mr-2 rounded-lg relative overflow-hidden">
                            <img
                                className="w-full h-full object-cover"
                                src={`/AA_ASSETS/eatsimplelogotransparent.png`}
                                alt="Background"
                            ></img>
                        </div>

                        <div className="h-full w-[80%] flex flex-col justify-center items-start p-2">
                            <div className="text-lg xs:text-xl md:text-2xl lg:text-3xl xl:text-4xl">{language === 'sv-SE' ? 'eatsimple Upphör Med Verksamheten' : 'eatsimple Suspending Operations'}</div>
                            <div className="mb-5">{language === 'sv-SE' ? 'Angelos Gustav Panou och Alex Karlsson' : 'Angelos Gustav Panou and Alex Karlsson'}</div>
                        </div>
                    </div>
                    
                    <div className="flex flex-row items-center justify-start w-full h-[18vh] p-1 relative cursor-no-drop">
                        <div className="absolute w-full h-full bg-[#1f1f1f] opacity-0 hover:opacity-5 duration-200 rounded-lg"></div>
                        <div className="h-[15vh] w-[15vh] mr-2 rounded-lg relative overflow-hidden">
                            <img
                                className="w-full h-full object-cover"
                                src={`/AA_ASSETS/techbehindeatsimple.avif`}
                                alt="Background"
                            ></img>
                        </div>

                        <div className="h-full w-[80%] flex flex-col justify-center items-start p-2">
                            <div className="text-lg xs:text-xl md:text-2xl lg:text-3xl xl:text-4xl">{language === 'sv-SE' ? 'Tekniken Som Driver eatsimple' : 'The Technology Behind eatsimple'}</div>
                            <div className="mb-5">{language === 'sv-SE' ? 'Angelos Gustav Panou' : 'Angelos Gustav Panou'}</div>
                        </div>
                    </div>

                    <div className="flex flex-row items-center justify-start w-full h-[18vh] p-1 relative cursor-no-drop">
                        <div className="absolute w-full h-full bg-[#1f1f1f] opacity-0 hover:opacity-5 duration-200 rounded-lg"></div>
                        <div className="h-[15vh] w-[15vh] mr-2 rounded-lg relative overflow-hidden">
                            <img
                                className="w-full h-full object-cover"
                                src={`/AA_ASSETS/efficiencyeatsimple.avif`}
                                alt="Background"
                            ></img>
                        </div>

                        <div className="h-full w-[80%] flex flex-col justify-center items-start p-2">
                            <div className="text-lg xs:text-xl md:text-2xl lg:text-3xl xl:text-4xl">{language === 'sv-SE' ? 'eatsimple Och Miljön' : 'eatsimple And The Environment'}</div>
                            <div className="mb-5">{language === 'sv-SE' ? 'Angelos Gustav Panou och Alex Karlsson' : 'Angelos Gustav Panou and Alex Karlsson'}</div>
                        </div>
                    </div>

                    <div className="flex flex-row items-center justify-start w-full h-[18vh] p-1 relative cursor-no-drop">
                        <div className="absolute w-full h-full bg-[#1f1f1f] opacity-0 hover:opacity-5 duration-200 rounded-lg"></div>
                        <div className="h-[15vh] w-[15vh] mr-2 rounded-lg relative overflow-hidden">
                            <img
                                className="w-full h-full object-cover"
                                src={`/AA_ASSETS/farm2.avif`}
                                alt="Background"
                            ></img>
                        </div>

                        <div className="h-full w-[80%] flex flex-col justify-center items-start p-2">
                            <div className="text-lg xs:text-xl md:text-2xl lg:text-3xl xl:text-4xl">{language === 'sv-SE' ? 'Värje Produkt Har En Historia' : 'Every Product Has A Story'}</div>
                            <div className="mb-5">{language === 'sv-SE' ? 'Alex Karlsson' : 'Alex Karlsson'}</div>
                        </div>
                    </div>

                    <div className="flex flex-row items-center justify-start w-full h-[18vh] p-1 relative cursor-no-drop">
                        <div className="absolute w-full h-full bg-[#1f1f1f] opacity-0 hover:opacity-5 duration-200 rounded-lg"></div>
                        <div className="h-[15vh] w-[15vh] mr-2 rounded-lg relative overflow-hidden">
                            <img
                                className="w-full h-full object-cover"
                                src={`/AA_ASSETS/farm1.avif`}
                                alt="Background"
                            ></img>
                        </div>

                        <div className="h-full w-[80%] flex flex-col justify-center items-start p-2">
                            <div className="text-lg xs:text-xl md:text-2xl lg:text-3xl xl:text-4xl">{language === 'sv-SE' ? 'Närproducerad Mat Direkt Till Din Dör - Eatsimple' : 'Locally Produced Food Direct To Your Door - eatsimple'}</div>
                            <div className="mb-5">{language === 'sv-SE' ? 'Alex Karlsson' : 'Alex Karlsson'}</div>
                        </div>
                    </div>
                </div>

                <div className="w-full h-[20vh] flex flex-row items-center justify-center mt-[20vh]">
                    <span className="text-xs xs:text-md mt-20">{language === 'sv-SE' ? 'tidigare registrerad som eatsimple LLC, 2024' : 'formerly registered as eatsimple LLC, 2024'}</span>
                </div>
            </div>
        </div>
    )
}