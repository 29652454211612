import { create } from "zustand";

export const useDarkModeStore = create((set) => ({
    isDarkMode: false, // default to light mode
    toggleDarkMode: () => set((state) => ({ isDarkMode: !state.isDarkMode })),
}));

const allowedLocales = ['sv-SE', 'en-US'];

export const useLanguageStore = create((set) => ({
    language: 'sv-SE', // default locale
    setLanguage: (newLocale) => {
        if (allowedLocales.includes(newLocale)) {
            set({ language: newLocale });
        } else {
            console.warn(`Invalid locale: ${newLocale}`);
        }
    },
}));

export const useTabStore = create((set) => ({
    isTabOpen: false, // default to closed
    toggleTabOpen: () => set((state) => ({ isTabOpen: !state.isTabOpen })),
}));