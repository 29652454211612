import { useNavigate } from "react-router-dom";
import { useLanguageStore, useTabStore } from "../../zustand/stores"

export default function EatsimpleSuspendingOperations () { 
    const { language, setLanguage } = useLanguageStore();
    const { isTabOpen, toggleTabOpen } = useTabStore();
    // const { isDarkMode, setIsDarkMode } = useDarkModeStore();
    const navigate = useNavigate();

    const handleNavigationToHome = () => {
        navigate('/');
    };

    const handleNavigationToBlog = () => {
        navigate('/blog');
    };

    const handleNavigationToBlogEnd = () => {
        navigate('/blog/eatsimple_suspending_operations');
    };

    return (
        <div className="bg-[#f4f1eb]">
            <div
                className={`fixed w-full flex flex-col justify-between bg-[#f4f1eb] z-10 transition-all duration-300 ${
                    isTabOpen ? "h-[40vh]" : "h-[6vh]"
                }`}
            >
                <div className="h-[6vh] flex flex-row justify-between">
                    <div 
                        className="xs:w-[20vw] flex flex-row text-2xl sm:text-4xl px-2 mt-1.5 xxs:mt-3 sm:mt-2 cursor-pointer" 
                        style={{letterSpacing: -0.4}}
                        onClick={() => {
                            handleNavigationToHome();
                            if (isTabOpen) {
                                toggleTabOpen();
                            }
                        }}
                    >
                        <div>
                            <img 
                                className="min-w-[35px] h-[35px] sm:w-[45px] sm:h-[45px] object-cover mr-1"
                                src={`/AA_ASSETS/eatsimplelogotransparent.png`}
                                alt="Background"
                            />
                        </div>
                        <span>eatsimple.se</span>
                    </div>

                    <div className="w-[0vw] sm:w-[60vw] h-[6vh] hidden sm:flex flex-row justify-center items-center gap-2">
                        <div className={`hover:opacity-100 duration-150 cursor-no-drop`}>{language === 'sv-SE' ? 'handla' : 'shop'}</div>
                        <div>|</div>
                        <div className={`hover:opacity-70 duration-150 cursor-no-drop`}>{language === 'sv-SE' ? 'om oss' : 'about us'}</div>
                        <div>|</div>
                        <div className={`hover:opacity-70 duration-150 cursor-pointer`} onClick={handleNavigationToBlog}>{language === 'sv-SE' ? 'blogg' : 'blog'}</div>
                        <div>|</div>
                        <div className={`hover:opacity-100 duration-150 cursor-no-drop`}>{language === 'sv-SE' ? 'logga in' : 'log in'}</div>
                    </div>

                    <div className="h-full w-[0vw] sm:w-[20vw] hidden sm:flex flex-row justify-end items-center text-xl gap-1">
                        <div
                            onClick={() => setLanguage('sv-SE')}
                            className={`cursor-pointer duration-300 ${
                            language === 'sv-SE'
                                ? 'opacity-100'
                                : 'opacity-60 hover:opacity-75'
                            }`}
                        >
                            sv
                        </div>

                        <div className="opacity-50">|</div>
                        
                        <div
                            onClick={() => setLanguage('en-US')}
                            className={`cursor-pointer duration-300 mr-[1vw] ${
                            language === 'en-US'
                                ? 'opacity-100'
                                : 'opacity-60 hover:opacity-75'
                            }`}
                        >
                            en
                        </div>
                    </div>

                    <div className="flex sm:hidden h-full w-[12vw] flex-row justify-center items-center mt-0.5 mr-1">
                        <div className={`bi ${isTabOpen ? 'bi-x' : 'bi-list'} text-2xl`} onClick={toggleTabOpen}></div>
                    </div>
                </div>

                <div
                    className={`${
                        isTabOpen ? "h-[34vh]" : "h-[0vh] hidden"
                    } sm:h-[0vh] flex flex-col justify-center items-center sm:hidden transition-all duration-300`}
                >
                    <div className="flex flex-col items-center gap-3">
                        <div className={`hover:opacity-100 duration-150 cursor-no-drop`}>{language === 'sv-SE' ? 'handla' : 'shop'}</div>
                        <div className={`hover:opacity-70 duration-150 cursor-no-drop`}>{language === 'sv-SE' ? 'om oss' : 'about us'}</div>
                        <div className={`hover:opacity-70 duration-150 cursor-pointer`} onClick={() => {handleNavigationToBlog(); toggleTabOpen();}} >{language === 'sv-SE' ? 'blogg' : 'blog'}</div>
                        <div className={`hover:opacity-100 duration-150 cursor-no-drop`}>{language === 'sv-SE' ? 'logga in' : 'log in'}</div>
                    </div>

                    <div className="sm:h-[7vh] mt-[3.5vh] w-[20vw] flex flex-row justify-center items-center sm:text-xl gap-2">
                        <div
                            onClick={() => {
                                setLanguage('sv-SE');
                                toggleTabOpen();
                            }}
                            className={`cursor-pointer duration-300 ${
                            language === 'sv-SE'
                                ? 'opacity-100'
                                : 'opacity-60 hover:opacity-75'
                            }`}
                        >
                            sv
                        </div>

                        <div className="opacity-50">|</div>
                        
                        <div
                            onClick={() => {
                                setLanguage('en-US');
                                toggleTabOpen();
                            }}
                            className={`cursor-pointer duration-300 ${
                            language === 'en-US'
                                ? 'opacity-100'
                                : 'opacity-60 hover:opacity-75'
                            }`}
                        >
                            en
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full flex flex-col items-center relative px-10">
                <div className="w-full h-[0vh] sm:h-[6vh] flex flex-row items-center justify-between mt-1"></div>
                
                <div className="w-full h-[18vh] sm:h-[20vh] mt-[8vh] sm:mt-[0vh] flex flex-col justify-end relative">
                    <div className="w-full flex flex-row justify-start">
                        <span className="text-2xl xs:text-3xl md:text-4xl lg:text-5xl xl:text-6xl">{language === 'sv-SE' ? 'eatsimple Upphör Med Verksamheten' : 'eatsimple Suspending Operations'}</span>
                    </div>
                    <div className="w-full flex flex-row justify-start">
                        <span className="text-md xs:text-lg md:text-xl lg:text-xl xl:text-2xl">{language === 'sv-SE' ? 'Angelos Gustav Panou och Alex Karlsson' : 'Angelos Gustav Panou and Alex Karlsson'}</span>
                    </div>
                </div>

                <div className="w-full flex flex-col items-center mt-5 gap-5 md:px-10 relative">
                    {language === 'sv-SE' ? (
                        <div className="flex flex-col items-start justify-start w-full min-h-[40vh] mt-5 p-1 relative">
                            <p>
                                På grund av ekonomiska begränsningar samt personliga utmaningar inom grundarteamet kommer eatsimple att pausa 
                                verksamheten tills vidare. Även om eatsimple fortsatt är engagerat i sin mission och det värde företaget har 
                                skapat, kräver dessa sammantagna faktorer att vi tar ett steg tillbaka för att omvärdera prioriteringar och 
                                utforska hållbara vägar framåt som möjliggör en ansvarsfull verksamhet och potentiella investeringar. Under 
                                denna period kommer teamet att fokusera på att hantera dessa utmaningar, se över strategin och utvärdera möjligheter 
                                att bygga en starkare grund för framtiden. Förhoppningen är att denna paus kommer att ge eatsimple möjlighet att 
                                återhämta sig, omvärdera och utvecklas till en starkare verksamhet och ett stabilare företag.
                            </p>

                            <p className="mt-7">
                                Vi vill ta tillfället i akt att uttrycka vår uppskattning till våra jordbrukspartners för deras samarbete och 
                                stöd till vår mission. Era bidrag har varit avgörande för vår plattforms framgång, och vi värdesätter det förtroende 
                                ni har visat oss under denna resa. Vi vill också tacka alla som har handlat via vår plattform. Ert engagemang har 
                                varit en nyckelfaktor för vår verksamhet, och vi uppskattar den viktiga roll ni har spelat i att hjälpa oss 
                                uppnå våra mål.
                            </p>

                            <p className="mt-7">
                                Till alla som har registrerat sig på vår plattform vill vi försäkra er om att era personuppgifter är säkra och hanteras 
                                i full överensstämmelse med gällande dataskyddslagstiftning, inklusive GDPR. Vi förstår vikten av att skydda era personuppgifter 
                                och har vidtagit alla nödvändiga åtgärder för att säkerställa dess sekretess och säkerhet under denna period av pausad verksamhet. 
                                Vid behov kan ni kontakta oss på contact@eatsimple.se för frågor.

                                Era uppgifter kommer inte att användas för några andra ändamål än de som uttryckligen överenskommits vid registreringen. Vi delar 
                                inte era personuppgifter med tredje part utan ert samtycke, om det inte krävs enligt lag. Alla databehandlingsaktiviteter 
                                kommer att förbli pausade under denna tid, förutom för nödvändiga uppgifter relaterade till regelefterlevnad eller på er 
                                specifika begäran.

                                Skulle vår operativa paus leda till förändringar i plattformens ägarskap eller struktur, kommer ni att informeras i förväg, 
                                särskilt om sådana förändringar påverkar behandlingen av era personuppgifter. Inga uppgifter kommer att överföras eller 
                                användas utanför sitt ursprungliga syfte utan föregående meddelande och, där det är nödvändigt, ert uttryckliga samtycke.
                            </p>

                            <p className="mt-7">
                                Vi är fortsatt hoppfulla om att kunna fortsätta vår mission.
                            </p>

                            <p className="mt-7">
                                Tack, eatsimple.
                            </p>
                        </div>
                    ) : (
                        <div className="flex flex-col items-start justify-start w-full min-h-[40vh] mt-5 p-1 relative">
                            <p>
                                Due to financial contrainsts, as well as personal contrainsts amongst the founding team, eatsimple
                                will be suspending operations until further notice. While eatsimple remains passionate about its mission 
                                and the impact it has achieved so far, these combined factors necessitate taking a step back to reassess 
                                priorities and explore sustainable paths forward that allow us to responsibly operate and take on potential 
                                investment. During this period, the team will focus on addressing these challenges, re-evaluating strategy, 
                                and considering opportunities to potentially rebuild stronger foundations for the future. The hope is that 
                                this pause will allow eatsimple to recoup, re-evaluate, and rebuild into a stronger operation and company.
                            </p>

                            <p className="mt-7">
                                We would like to take this opportunity to express our gratitude to our farm partners for their collaboration 
                                and for supporting our mission. Your contributions have been integral to the success of our platform, and we 
                                appreciate the trust you placed in us throughout this journey. We would also like to thank all those who 
                                purchased from our platform. Your engagement has been a key driver of our operations, and we value the role 
                                you played in helping us achieve our objectives.
                            </p>

                            <p className="mt-7">
                                To all those who have registered on our platform, we want to assure you that your data remains secure and will 
                                be handled in full compliance with applicable data protection laws, including GDPR. We understand the importance 
                                of safeguarding your personal information, and we have taken all necessary measures to ensure its confidentiality 
                                and security during this period of suspended operations. If necessary, contact contact@eatsimple.se for enquires.
                                Your data will not be used for any purposes other than those explicitly agreed upon at the time of registration. 
                                We do not share your personal information with third parties without your consent, unless required by law. Any data 
                                processing activities will remain paused during this time, except for essential compliance-related tasks or upon 
                                your specific request. Additionally, should our operational pause lead to any change in the ownership or structure 
                                of the platform, you will be notified in advance, particularly if such changes affect the processing of your personal 
                                data. No data will be transferred or used outside the scope of its original purpose without prior notification and, 
                                where necessary, your explicit consent.
                            </p>

                            <p className="mt-7">
                                We remain hopeful to be able to continue our mission.
                            </p>

                            <p className="mt-7">
                                Thank you, eatsimple.
                            </p>
                        </div>
                    )}
                </div>

                <div className="w-full h-[20vh] flex flex-row items-center justify-center mt-[20vh]">
                    <span className="text-xs xs:text-md mt-20">{language === 'sv-SE' ? 'tidigare registrerad som eatsimple LLC, 2024' : 'formerly registered as eatsimple LLC, 2024'}</span>
                </div>
            </div>
        </div>
    )
}