import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home/home';
import Blog from './pages/blog/blog';
import EatsimpleSuspendingOperations from './pages/blog/eatsimpleSuspendingOperations';
import NotFound from './pages/404';

function App() {
	return (
		<Router>
			<Routes>
				<Route path='/' element={<Home />}/>
				<Route path='/blog' element={<Blog />}/>
				<Route path="/blog/eatsimple_suspending_operations" element={<EatsimpleSuspendingOperations />} />

				<Route path="*" element={<NotFound />} />
			</Routes>
		</Router>
	);
}

export default App;
